import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { NgCalendarModule } from 'ionic2-calendar';
import { environment } from '../environments/environment';
import { AdmitePedidoDirective } from './admite-pedido.directive';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './common/interceptor/JWTInterceptor';
import { TokenRefreshInterceptor } from './common/interceptor/TokenRefreshInterceptor';
import { LocalCardComponent } from './common/local-card/local-card.component';
import { ShareComponent } from './common/share/share.component';
import { TopbarComponent } from './common/topbar/topbar.component';
import { EstaLogueadoAG } from './components/Authguards/EstaLogueadoAG';
import { MayorCheckAG } from './components/Authguards/MayorCheckAG';
import { BtnRemoveAddComponent } from './components/btn-remove-add/btn-remove-add.component';
import { ModalFiltroComponent } from './components/modal-filtro/modal-filtro.component';
import { ModalPedidoComponent } from './components/modal-pedido/modal-pedido.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { PromocionDetalleComponent } from './components/promocion-detalle/promocion-detalle.component';
import { RestriccionesComponent } from './components/restricciones/restricciones.component';
import { SliderItemComponent } from './components/slider-item/slider-item.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { AgregarPedidoComponent } from './pages/agregar-pedido/agregar-pedido.component';
import { CheckoutPage } from './pages/checkout/checkout.page';
import { EstadoPagoComponent } from './pages/estado-pago/estado-pago.component';
import { FooterComponent } from './pages/footer/footer.component';
import { HomePage } from './pages/home/home.page';
import { ItemPedidoComponent } from './pages/item-pedido/item-pedido.component';
import { LandingPage } from './pages/landing/landing.page';
import { LoginComponent } from './pages/login/login.component';
import { MenuPage } from './pages/menu/menu.page';
import { MiMesaComponent } from './pages/mi-mesa/mi-mesa.component';
import { MisPedidosComponent } from './pages/mis-pedidos/mis-pedidos.component';
import { NuevaReservaComponent } from './pages/nueva-reserva/nueva-reserva.component';
import { PedidoPage } from './pages/pedido/pedido.page';
import { ProductoPage } from './pages/producto/producto.page';
import { RegisterComponent } from './pages/register/register.component';
import { ReservasComponent } from './pages/reservas/reservas.component';
import { SeVienePage } from './pages/se-viene/se-viene.page';
import { SignupPage } from './pages/signup/signup.page';
import { AuthService } from './services/auth.service';
import { HomeService } from './services/home.service';
import { LocalService } from './services/local.service';
import { MenuService } from './services/menu.service';
import { PedidoService } from './services/pedido.service';
import { ProductoService } from './services/producto.service';
// import { LoginService } from './services/login.service';
import { DigitOnlyDirective } from './utils/digit-only.directive';
import { TagsPipe } from './utils/tags.pipe';

registerLocaleData(es)
const routes: Routes = [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing', component: LandingPage, canActivate: [MayorCheckAG] },
    { path: 'home', component: HomePage, canActivate: [MayorCheckAG] },
    { path: 'mis-pedidos', component: MisPedidosComponent, canActivate: [MayorCheckAG] },
    { path: 'mis-pedidos/refresh', component: MisPedidosComponent, canActivate: [MayorCheckAG] },
    { path: 'menu', component: MenuPage, canActivate: [MayorCheckAG] },
    { path: 'menu/:idLocal/:idMenu', component: MenuPage, canActivate: [MayorCheckAG] },
    { path: 'reservas/:idLocal', component: NuevaReservaComponent, canActivate: [MayorCheckAG] },
    { path: 'producto/:idProducto/:idMenu/:idLocal', component: MenuPage, canActivate: [MayorCheckAG] },
    { path: 'promocion-detalle', component: PromocionDetalleComponent, canActivate: [MayorCheckAG] },
    { path: 'pedido', component: PedidoPage, canActivate: [MayorCheckAG] },
    { path: 'checkout', component: CheckoutPage, canActivate: [MayorCheckAG] },
    { path: 'reservas', component: ReservasComponent, canActivate: [MayorCheckAG] },
    { path: 'nueva-reserva', component: NuevaReservaComponent, canActivate: [MayorCheckAG] },
    { path: 'estado-pago/:trxId', component: EstadoPagoComponent, canActivate: [MayorCheckAG] },
    { path: 'footer', component: FooterComponent, canActivate: [MayorCheckAG] },
    { path: 'agregar-pedido', component: AgregarPedidoComponent, canActivate: [MayorCheckAG] },
    { path: 'agregar-pedido/:numeroPedido/:idLocal/:codigoVerificacion', component: AgregarPedidoComponent, canActivate: [MayorCheckAG] },
    { path: 'not-allowed', component: NotAllowedComponent },
    { path: 'login', component: LoginComponent, canActivate: [MayorCheckAG] },
    { path: 'register', component: RegisterComponent, canActivate: [MayorCheckAG, EstaLogueadoAG] },
    { path: 'mesa', component: MiMesaComponent, canActivate: [MayorCheckAG] },    
    { path: 'l/:identificadorUrl', component: LandingPage, canActivate: [MayorCheckAG] },
    { path: 'mesa/:idLocacion/:idMesa', component: MiMesaComponent, canActivate: [MayorCheckAG] },
    { path: 'mi-mesa', component: MiMesaComponent, canActivate: [MayorCheckAG] },
]

@NgModule({
    declarations: [
        AppComponent,
        BtnRemoveAddComponent,
        ModalPedidoComponent,
        ProductItemComponent,
        SliderItemComponent,
        CheckoutPage,
        HomePage,
        ItemPedidoComponent,
        LandingPage,
        MenuPage,
        MisPedidosComponent,
        PedidoPage,
        ProductoPage,
        SignupPage,
        PromocionDetalleComponent,
        SeVienePage,
        TagsPipe,
        TopbarComponent,
        ReservasComponent,
        DigitOnlyDirective,
        NuevaReservaComponent,
        EstadoPagoComponent,
        AgregarPedidoComponent,
        FooterComponent,
        LoginComponent,
        ModalFiltroComponent,
        RegisterComponent,
        NotAllowedComponent,
        ShareComponent,
        MiMesaComponent,
        LocalCardComponent,
        AdmitePedidoDirective,
        RestriccionesComponent
    ],
    entryComponents: [
        ModalPedidoComponent,
        CheckoutPage,
        HomePage,
        LandingPage,
        MenuPage,
        MisPedidosComponent,
        PedidoPage,
        ProductoPage,
        PromocionDetalleComponent,
        SignupPage,
        EstadoPagoComponent,
        FooterComponent,
        LoginComponent,
        ModalFiltroComponent,
        RegisterComponent,
        NotAllowedComponent,
        ShareComponent,
        MiMesaComponent,
        RestriccionesComponent
    
    ],
    imports: [
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgCalendarModule,
        IonicModule.forRoot({
            _forceStatusbarPadding: true,
        }),
        IonicStorageModule.forRoot(),
        HttpClientModule,
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),

        RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Vibration,
        AuthService,
        HomeService,
        MenuService,
        PedidoService,
        LocalService,
        QRScanner,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenRefreshInterceptor,
            multi: true,
        },
        ProductoService,
        { provide: LOCALE_ID, useValue: 'es-ES' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: '€' },
        
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

