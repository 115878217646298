// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    //apiUrl: 'assets/data'
    apiUrl: 'https://tucomercio-back.es.costazini.com.ar/tucomercio_es-backend/',
    //apiUrl: "https://api.tucomercio.ar/tucomercio-backend/",
    tokenKey: 'tucomercio_es-backend-test',
    userApp: 'appuser',
    passwordApp: 'admin1234',
    firebaseConfig: {
        apiKey: 'AIzaSyCNt3e7FZcu-eCmjklLfasbFZcjSc-TtaQ',
        authDomain: 'tucomercio-es-test.firebaseapp.com',
        projectId: 'tucomercio-es-test',
        storageBucket: 'tucomercio-es-test.appspot.com',
        messagingSenderId: '459372007534',
        appId: '1:459372007534:web:67f27d422fbc5457ee567f',
        measurementId: 'G-BVXMR5BJ4N',
    },
    layoutItem: 'v',
    soloMenu: false,    
    localDefaut:null,
    conMesas: false,
    requiereValidacionEdad: false,
    pedidosExternos: true,
    admiteReservas:true,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
