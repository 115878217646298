import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, LoadingController, ToastController } from '@ionic/angular'
import * as moment from 'moment'
import { Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { AuthService } from 'src/app/services/auth.service'
import { Local } from './../../model/Local'
import { Reserva } from './../../model/Reserva'
import { TotalizadorOcupacion } from './../../model/TotalizadorOcupacion'
import { TurnoReserva } from './../../model/TurnoReserva'
import { LocalService } from './../../services/local.service'
import { ReservaService } from './../../services/reservas.service'
import { TranslateService } from './../../services/translate.service'
@Component({
    selector: 'app-nueva-reserva',
    templateUrl: './nueva-reserva.component.html',
    styleUrls: ['./nueva-reserva.component.scss'],
})
export class NuevaReservaComponent implements OnInit {
    constructor(
        private tService: TranslateService,
        private reservaService: ReservaService,
        public loadingController: LoadingController,
        public router: Router,
        public alertController: AlertController,
        public toastController: ToastController,
        public localService: LocalService,
        private activatedRoute: ActivatedRoute,
        public authService: AuthService
    ) {}
    private fechaChange: Subject<Date> = new Subject()
    public reserva: Reserva
    private _fecha: Date
    public editandoFecha: boolean = true
    public localElegido: boolean = false
    public editable: boolean = true

    private _cantidadPersona: number
    public get cantidadPersona(): number {
        return this._cantidadPersona
    }
    public set cantidadPersona(v: number) {
        this.reserva.cantidadPersonas = v
    }

    public get fecha(): Date {
        return this._fecha
    }
    public set fecha(v: Date) {
        this._fecha = v
        if (this._fecha) {
            this.updateOcupacion()
        }
    }

    public calendarOptions
    public header
    private fechaMaxima: Date
    public ocupacion: TotalizadorOcupacion

    private _local: Local
    public get local(): Local {
        return this._local
    }
    public set local(v: Local) {
        if (v != this._local) {
            this._local = v
            this.reserva.local = this.local
            this.fechaMaxima = this._local?.diasAntelacion >= 0 ? moment().add(this._local.diasAntelacion, 'days').endOf('date').toDate() : null
            this.localElegido = v?.id ? true : false
        }
    }
    public localesReserva: Local[] = []
    ngOnInit() {
        this.calendarOptions = {
            monthPickerFormat: [],
            weekdays: [],
            currentDate: new Date(),
            queryMode: 'remote',
            mode: 'month',
        }
        this.activatedRoute.params.subscribe((params) => {
            this.localService.getById(params.idLocal).then((l) => {
                this.localClick(null, l)
            })
        })
        this.reserva = new Reserva()
        this.onRangeChanged({ startTime: moment().startOf('month').toDate(), endTime: moment().endOf('month') })
        this.reservaService.reservaEditadaObs.subscribe(async (r) => {
            if (r) {
                this.reserva = r
                if (this.reserva.id) {
                    this.editable = false
                }
                this.local = await this.localService.getById(r.local?.id)
                this.localElegido = this.local != undefined
            }
        })

        if (this.localService.local?.admiteReserva) {
            this.localElegido = true
            this.local = this.localService.local
        }
        /*this.localService.data.pipe(map((l) => l.filter((loc) => loc.admiteReserva).map((loc) => Local.fromData(loc)))).subscribe((l) => {
            this.localesReserva = l
            if (this.reserva.local) {
                this.local = l.filter((ll) => ll.id == this.reserva.local.id)[0]
                this.localElegido = this.local != undefined
            }
            if (!this.localElegido && this.localesReserva.length == 1) {
                this.local = l[0]
                this.localElegido = this.local != undefined
            }
        })*/

        this.localService.localSeleccionado.pipe(filter((l) => l != undefined)).subscribe((l) => {
            if (l?.admiteReserva) {
                this.local = l
                this.localElegido = this.local != undefined
                this.updateOcupacion()
            }
        })

        this.tService.upateData.pipe(filter((d) => d != undefined)).subscribe((d) => {
            let cal = this.tService.get('CALENDARIO_LOCALE')
            if (cal) {
                this.calendarOptions.weekdays = cal.dayNamesMin
                this.calendarOptions.monthPickerFormat = cal.monthNamesShort
            }
        })

        if (this.authService.hayLogueado() && !this.authService.esUserDefault()) {
            this.reserva.nombre = this.authService.register.nombre
            this.reserva.telefonoContacto = this.authService.register.telefono
            this.reserva.emailContacto = this.authService.register.email
        }
    }
    public localClick = (event, l: Local) => {
        if (this.localesReserva?.length == 1 || this.reserva.id) {
            if (this.local?.id != l?.id) this.local = l
            return
        }
        if (this.localElegido && this.localesReserva?.length > 1) {
            this.localElegido = false
            this.local = null
            event?.stopPropagation && event.stopPropagation()
        } else {
            this.local = l
            this.localElegido = this.local != undefined
        }
    }
    public isValid() {
        return (
            this.reserva.local &&
            this.reserva.fecha &&
            this.reserva.horario &&
            this.reserva.cantidadPersonas &&
            this.reserva.emailContacto &&
            this.reserva.telefonoContacto
        )
    }
    public horariosDisponibles: HorarioReserva[] = []

    private _horarioSeleccionado: HorarioReserva
    public get horarioSeleccionado(): HorarioReserva {
        return this._horarioSeleccionado
    }
    public set horarioSeleccionado(v: HorarioReserva) {
        this._horarioSeleccionado = v
    }

    public seleccionarHorario(horario: HorarioReserva) {
        this.horarioSeleccionado = horario
        if (this.editable && horario.disponibles >= this.reserva.cantidadPersonas) this.reserva.horario = horario.horario
    }

    public async cancelarReserva(r: Reserva) {
        const a = await this.alertController.create({
            header: 'Cancelar reserva',
            message: 'Va a cancelar la reserva #' + r.id + '. Desea continuar?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {},
                },
                {
                    text: 'Cancelar reserva',
                    handler: () => {
                        this.reservaService
                            .cancelarReserva(r)
                            .then(async (r) => {
                                const t = await this.toastController.create({
                                    message: 'Reserva cancelada',
                                    duration: 1000,
                                })
                                t.present()
                                this.reservaService.quitarReserva(r)
                                this.router.navigate(['reservas'])
                                return
                            })
                            .catch(async (e) => {
                                const t = await this.toastController.create({
                                    message: 'Error al cancelar la reserva. Intente mas tarde.',
                                    duration: 2000,
                                })
                                t.present()
                                return
                            })
                    },
                },
            ],
        })
        a.present()
    }
    public get cancelable() {
        return this.reserva.id && moment(this.reserva.fecha).isAfter(moment(new Date()).endOf('day')) && this.reserva.noFinalizado()
    }

    private updateOcupacion() {
        if (this.fecha && this.local) {
            var t: TurnoReserva[] = this.local.getTurnosByFecha(this.fecha)
            this.horariosDisponibles = t.reduce(
                (flat, turno) =>
                    flat.concat(turno.horarios.map((h) => new HorarioReserva(h, this.ocupacion.getPersonasByFecha(this.fecha, h), turno.capacidad))),
                []
            )
        }
    }
    private ocupadas: Date[] = []
    private loading
    public async displayLoading(message: string) {
        this.loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: message,
        })
        await this.loading.present()
    }
    public async dismissLoading() {
        if (this.loading) {
            this.loading.dismiss()
        }
    }
    onViewTitleChanged(title) {
        this.header = title
    }
    onRangeChanged(event) {
        var start = moment(event.startTime).startOf('date')
        var end = moment(event.endTime).endOf('date')
        this.reservaService.getOcupadas(start.toDate(), end.toDate(), this.local).then((r) => {
            this.ocupacion = new TotalizadorOcupacion(r, start.toDate(), end.toDate(), this.local)
            this.ocupadas = this.ocupacion.ocupados
        })
    }

    onCurrentDateChanged(event) {
        this.fecha = event
        this.reserva.fecha = event
    }
    markDisabled = (date: Date) => {
        const current = moment(new Date()).startOf('date')
        const dateToTest = moment(date).startOf('date')
        var sinHorarios = this.local && this.local.getTurnosByFecha(date).length == 0

        return (
            (this._local?.fechaMaximaReserva && moment(this._local.fechaMaximaReserva).endOf('date').isSameOrBefore(dateToTest)) ||
            (this.fechaMaxima && dateToTest.isSameOrAfter(this.fechaMaxima)) ||
            current.isAfter(dateToTest) ||
            this.ocupadas.some((d) => moment(d).isSame(date, 'date')) ||
            sinHorarios
        )
    }

    async confirmarReserva() {
        if (this.horarioSeleccionado.disponibles < this.reserva.cantidadPersonas) {
            const t = await this.toastController.create({
                message: 'Seleccionó un horario sin capacidad suficiente',
                duration: 2000,
            })
            return t.present()
        }
        if (moment(this.reserva.fecha).isBefore(moment().startOf('date'))) {
            const t = await this.toastController.create({
                message: 'No puede realizar una reserva en el pasado',
                duration: 2000,
            })
            return t.present()
        }
        this.displayLoading('Guardando reserva')
        this.reservaService
            .registrarReserva(this.reserva)
            .then((r) => {
                return this.alert('Reserva solicitada', '#' + r.numeroReserva, () => {
                    // this.reservaService.agregarReserva(r)
                    this.reservaService.reservaEditada = null
                    this.router.navigate(['reservas'])
                })
            })
            .catch((e) => {
                return this.alert('Error', e?.message || 'No se pudo realizar la reserva')
            })
            .finally(() => {
                this.dismissLoading()
            })
    }
    public async alert(titulo: string, mensaje: string, onDismiss?) {
        const alert = await this.alertController.create({
            header: titulo,
            message: mensaje,
            buttons: ['Ok'],
        })
        if (onDismiss)
            alert.onDidDismiss().then((r) => {
                onDismiss()
            })
        await alert.present()
    }
}

export class HorarioReserva {
    constructor(public horario: string, public ocupacion: number = 1, public capacidad: number = 0) {}
    public get disponibles() {
        const v = this.capacidad - this.ocupacion
        return v < 0 ? 0 : v
    }
    public get percOcupacion() {
        const v = Math.round((this.ocupacion / (this.capacidad ? this.capacidad : this.ocupacion)) * 100)
        return v > 100 ? 100 : v
    }
}
